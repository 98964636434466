<template>
  <v-layout wrap>
    <v-flex xs12 class="title">
      <h2 
      >
        Acttiv api
      </h2>
    </v-flex>
    <template v-for="(item, index) of cronData">
      <v-flex 
        xs4 
        class="item" 
        style="text-align: left;"        
        :style="{ color: prepareColor(item) }"
      >
        {{ item.ID }}
      </v-flex>
      <v-flex xs2 class="item" 
        :style="{ color: prepareColor(item) }"
      >
        {{ item.Total }}
      </v-flex>
      <v-flex xs6 class="item" 
        :style="{ color: prepareColor(item) }"
      >
        [{{ item.Dif }}]
        {{item.CD.replace('T', '').split('.').shift()}} 
      </v-flex>
    </template>
    <v-flex xs12 class="action">
      <a @click="handleGetCronResult">refresh</a>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios'
export default {
  data: () => ({
    cronData: [],
  }),
  mounted () {
    this.handleGetCronResult()
  },
  methods: {
    prepareColor  (v) {
      let second = 0
      let aux = v.Dif.split(':').map(item => item | 0)
      second += aux.pop()
      second += aux.pop() * 60
      second += aux.pop() * 60 * 60
      
      if (v.ID === 'hotels' && second > 86400) return 'red'
      if (v.ID === 'categories' && second > 86400) return 'red'
      if (v.ID === 'pias' && second > 1800) return 'red'
      return 'black'
    },
    handleGetCronResult () {
      axios.get(`https://acttiv-api.dtouchwebapp.com/v1/public/results`)
        .then(response => {
          if (!response) return 
          this.cronData = response.data
          //this.lastIntervalInHours = (response.data.time / 60).toFixed(2)
        })
    },
  },
}
</script>
<style scoped>
.title {
  background-color: #ccc;
  text-align: center;
  padding: 20px 10px;
}
.item {
  text-align: center;
  padding: 20px 10px;
  border-bottom: 1px solid #CCCCCC80;
}
.action {
  padding: 10px;
  text-align: right;
}
</style>

